import CardDashboardStat from "@components/card/CardDashboardStat";
import CardDashboardStatGroup from "@components/card/CardDashboardStatGroup";
import AgenciesAmountISOColumnChart from "@components/chart/AgenciesAmountISOColumnChart";
import AgencyAmountByAgencyTypePieChart from "@components/chart/AgencyAmountByAgencyTypePieChart";
import AgencyISOStatsGroup from "@components/chart/AgencyISOStatsGroup";
import AgencyRankingChart from "@components/chart/AgencyRankingChart";
import AgencyTableListISOChart from "@components/chart/AgencyTableListISOChart";
import ChairmanRankingChart from "@components/chart/ChairmanRankingChart";
import ChartFlexSection from "@components/chart/ChartFlexSection";
import CoordinatorRankingChart from "@components/chart/CoordinatorRankingChart";
import LecturerRankingChart from "@components/chart/LecturerRankingChart";
import ProjectBudgetByAgencyTypePieChart from "@components/chart/ProjectBudgetByAgencyTypePieChart";
import ProjectBudgetByProjectTypePieChart from "@components/chart/ProjectBudgetByProjectTypePieChart";
import ProjectBudgetByQuarterColumnChart from "@components/chart/ProjectBudgetByQuarterColumnChart";
import ProjectEvaluationColumnChart from "@components/chart/ProjectEvaluationColumnChart";
import ProjectRankingChart from "@components/chart/ProjectRankingChart";
import ProjectRankingEvaluationChart from "@components/chart/ProjectRankingEvaluationChart";
import VendorRankingChart from "@components/chart/VendorRankingChart";
import LoadingPage from "@components/loading/LoadingPage";
import PageLayout from "@components/page/PageLayout";
import TextChartTitle from "@components/text/TextChartTitle";
import TextDashboardSectionTitle from "@components/text/TextDashboardSectionTitle";
import { IRequestSingleDashboard } from "@interfaces/api/IDashboard";
import { Button, Flex, Paper } from "@mantine/core";
import exportDataDashboardToExcel from "@utils/exportDataDashboardToExcel";
import Percen from "@utils/Percen";
import _ from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
import useLoadDashboardData from "../../hooks/useLoadDashboardData";

const DashboardSingle = () => {
  const { state }: { state: IRequestSingleDashboard } = useLocation();
  const {
    budget_year,
    projectLecturerData,
    projectCustomerData,
    projectData,
    projectVendorData,
    projectAgencyData,
    isDataNotFound,
    isDataLoading,
  } = useLoadDashboardData({ state });

  const pageTitle = `สรุปผลการดำเนินโครงการประจำปีงบประมาณ ${budget_year}`;

  // const allAgency = _.uniqBy(projectData, 'agency_name').length
  const allVendor = _.uniqBy(projectVendorData, "vendor_name").length;
  const newVendor = _.uniqBy(
    _.filter(projectVendorData, {
      vendor_budget_year: Number(state?.start_budget_year),
    }),
    "vendor_name",
  ).length;
  const oldVendor = _.uniqBy(
    _.filter(
      projectVendorData,
      (p) => p.vendor_budget_year !== Number(state?.start_budget_year),
    ),
    "vendor_name",
  ).length;

  if (isDataLoading) {
    return <LoadingPage />;
  }

  if (isDataNotFound) {
    return <PageLayout pageTitle={pageTitle}>ไม่พบข้อมูล</PageLayout>;
  }

  return (
    <PageLayout pageTitle={pageTitle}>
      <Paper mb="lg" className="noprint">
        <Button mr="lg" onClick={() => window.print()}>
          ดาวน์โหลด PDF
        </Button>
        <Button
          mr="lg"
          onClick={() =>
            exportDataDashboardToExcel(
              {
                projectData: projectData,
                projectLecturerData: projectLecturerData,
                projectVendorData: projectVendorData,
              },
              "reportdata.xlsx",
            )
          }
        >
          ดาวน์โหลด Excel
        </Button>
      </Paper>
      <Flex direction="column">
        {projectData.length > 0 && (
          <>
            <div>
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลโครงการ
              </TextDashboardSectionTitle>
              {state?.opt1_project_stats && (
                <div>
                  <ChartFlexSection>
                    <TextChartTitle>
                      สถิติยอดจำนวนโครงการ และมูลค่าของโครงการทั้งหมด
                      ประจำปีงบประมาณ {budget_year}
                    </TextChartTitle>
                    <CardDashboardStatGroup>
                      <CardDashboardStat
                        w={150}
                        label="ปีงบประมาณ"
                        value={budget_year}
                      />
                      <CardDashboardStat
                        w={200}
                        unit="โครงการ"
                        label="จำนวนโครงการ"
                        value={projectData.length}
                      />
                      <CardDashboardStat
                        w={350}
                        label="มูลค่าโครงการ"
                        unit="บาท"
                        isMoney
                        value={_.sumBy(projectData, (e) =>
                          Number(e.project_budget),
                        )}
                      />
                    </CardDashboardStatGroup>
                  </ChartFlexSection>
                </div>
              )}

              {state?.opt2_project_quarter && (
                <div className="pagebreak">
                  <ProjectBudgetByQuarterColumnChart
                    title={`ยอดมูลค่าโครงการทั้งหมดแบ่งสัดส่วนตามไตรมาส ประจำปีงบประมาณ ${budget_year}`}
                    data={projectData}
                  />
                </div>
              )}
              {state?.opt3_6_budget_by_project_type && (
                <div className="pagebreak">
                  <ProjectBudgetByProjectTypePieChart
                    title={`ยอดมูลค่าโครงการทั้งหมด แบ่งสัดส่วนตามประเภทกิจกรรมบริการวิชาการ ประจำปีงบประมาณ ${budget_year}`}
                    typeName="ประเภทกิจกรรมบริการวิชาการ"
                    valueName="งบประมาณ"
                    data={projectData}
                  />
                </div>
              )}
              {state?.opt3_project_budget_ranking && (
                <div className="pagebreak">
                  <ProjectRankingChart
                    title={`จัดอันดับโครงการสำคัญที่มียอดมูลค่าโครงการสูงสุด 5 อันดับแรก ประจำปีงบประมาณ ${budget_year}`}
                    data={projectData}
                  />
                </div>
              )}
              {state?.opt3_4_project_rating_overall && (
                <div className="pagebreak">
                  <ProjectEvaluationColumnChart
                    title={`ภาพรวมความพึงพอใจโครงการ ประจำปีงบประมาณ ${budget_year}`}
                    data={projectData}
                  />
                </div>
              )}
              {state?.opt3_5_project_rating_ranking && (
                <div className="pagebreak">
                  <ProjectRankingEvaluationChart
                    title={`จัดอันดับโครงการตามคะแนนความพึงพอใจ ประจำปีงบประมาณ ${budget_year}`}
                    data={projectData}
                  />
                </div>
              )}
            </div>
            <div className="pagebreak">
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลหน่วยงาน/ลูกค้า
              </TextDashboardSectionTitle>
              {state?.opt4_agency_stats && (
                <AgencyISOStatsGroup data={projectCustomerData} />
              )}
              {state?.opt5_agency_new_and_old && (
                <AgenciesAmountISOColumnChart
                  title={`ยอดจำนวนลูกค้ารายใหม่และรายเก่า ประจำปีงบประมาณ ${budget_year}`}
                  data={projectCustomerData}
                  keySort={"quarter"}
                  keyValue={"project_budget"}
                  keyType={"quarter"}
                  typeName="ปีงบประมาณ"
                  valueName="จำนวนหน่วยงาน"
                />
              )}
            </div>
            {state?.opt6_agency_list && (
              <>
                <div className="pagebreak">
                  <AgencyTableListISOChart
                    data={
                      projectCustomerData[state.start_budget_year].oldcustomer
                    }
                    title={`รายการข้อมูลลูกค้ารายเก่า ประจำปีงบประมาณ ${budget_year} (${
                      projectCustomerData[state.start_budget_year].oldcustomer
                        .length
                    } หน่วยงาน) `}
                  />
                </div>
                <div className="pagebreak">
                  <AgencyTableListISOChart
                    data={
                      projectCustomerData[state.start_budget_year].newcustomer
                    }
                    title={`รายการข้อมูลลูกค้ารายใหม่ ประจำปีงบประมาณ ${budget_year} (${
                      projectCustomerData[state.start_budget_year].newcustomer
                        .length
                    } หน่วยงาน) `}
                  />
                </div>
              </>
            )}
            <div className="pagebreak">
              {state?.opt7_agency_by_type && (
                <div className="pagebreak">
                  <AgencyAmountByAgencyTypePieChart
                    title={`ยอดจำนวนลูกค้าทั้งหมด แบ่งสัดส่วนตามประเภทหน่วยงาน ประจำปีงบประมาณ ${budget_year}`}
                    typeName="ประเภทหน่วยงาน"
                    valueName="หน่วยงาน"
                    data={projectAgencyData}
                  />
                </div>
              )}
              {state?.opt8_budget_by_agency_type && (
                <div className="pagebreak">
                  <ProjectBudgetByAgencyTypePieChart
                    title={`ยอดมูลค่าโครงการทั้งหมด แบ่งสัดส่วนตามประเภทหน่วยงาน ประจำปีงบประมาณ ${budget_year}`}
                    typeName="ประเภทหน่วยงาน"
                    valueName="งบประมาณ"
                    data={projectData}
                  />
                </div>
              )}
            </div>
            {state?.opt9_agency_budget_ranking && (
              <div className="pagebreak">
                <AgencyRankingChart data={projectData} />
              </div>
            )}
            <div className="pagebreak">
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลผู้ประสานงานโครงการ
              </TextDashboardSectionTitle>
              {state?.opt10_coodinator_budget_rank && (
                <CoordinatorRankingChart data={projectData} />
              )}
            </div>

            <div className="">
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลประธานโครงการ
              </TextDashboardSectionTitle>
              {state?.opt11_chairman_budget_rank && (
                <ChairmanRankingChart data={projectData} />
              )}
            </div>
          </>
        )}
        {projectLecturerData.length > 0 && (
          <div className="">
            <TextDashboardSectionTitle>
              สรุปผลข้อมูลวิทยากร
            </TextDashboardSectionTitle>
            {state?.opt12_lecturer_rating_rank && (
              <LecturerRankingChart data={projectLecturerData} />
            )}
          </div>
        )}
        {projectVendorData.length > 0 && (
          <div className="">
            <TextDashboardSectionTitle>
              สรุปผลข้อมูลผู้ค้า
            </TextDashboardSectionTitle>
            {state?.opt13_vendor_stats && (
              <ChartFlexSection>
                <TextChartTitle>
                  สถิติยอดจำนวนผู้ค้าทั้งหมด
                  และจำนวนผู้ค้ารายเก่าและผู้ค้ารายใหม่ ประจำปีงบประมาณ{" "}
                  {budget_year}
                </TextChartTitle>
                <CardDashboardStatGroup>
                  <CardDashboardStat
                    w={150}
                    label="ปีงบประมาณ"
                    value={budget_year}
                  />

                  <CardDashboardStat
                    w={200}
                    unit="หน่วยงาน"
                    label="จำนวนผู้ค้าทั้งหมด"
                    value={allVendor}
                  />
                  <CardDashboardStat
                    w={200}
                    unit="หน่วยงาน"
                    label="จำนวนผู้ค้ารายเก่า"
                    value={`${oldVendor} (${Percen(oldVendor, allVendor)}%)`}
                  />
                  <CardDashboardStat
                    w={200}
                    unit="หน่วยงาน"
                    label="จำนวนผู้ค้ารายใหม่"
                    value={`${newVendor} (${Percen(newVendor, allVendor)}%)`}
                  />
                </CardDashboardStatGroup>
              </ChartFlexSection>
            )}
            {state?.opt14_vendor_rating_rank && (
              <VendorRankingChart data={projectVendorData} />
            )}
          </div>
        )}
      </Flex>
    </PageLayout>
  );
};

export default DashboardSingle;
