import { Divider, Text, TextProps } from "@mantine/core";
import React from "react";

const TextDashboardSectionTitle = (prop: TextProps) => {
  return (
    <>
      <Divider my="md" />
      <Text color="gray" size="xl" mb="sm" fw="bold" {...prop}>
        {prop.children}
      </Text>
    </>
  );
};

export default TextDashboardSectionTitle;
