import React, { useEffect, useState } from "react";
import {
  IclassifiedNewAndOldCustomerByYear,
  IcleanCustomerData,
  IRequestDataForDashboard,
  IRequestMutipleDashboard,
  IRequestSingleDashboard,
  IResponseGetProjectCustomerDataForDashboard,
  IResponseGetProjectCustomerDataForDashboardRowData,
  IResponseGetProjectDataForDashboard,
  IResponseGetProjectDataForDashboardRowData,
  IResponseGetProjectLecturerDataForDashboard,
  IResponseGetProjectLecturerDataForDashboardRowData,
  IResponseGetProjectVendorDataForDashboard,
  IResponseGetProjectVendorDataForDashboardRowData,
} from "@interfaces/api/IDashboard";
import api from "@config/api";
import { notifications } from "@mantine/notifications";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

type Props = {
  state: IRequestSingleDashboard | IRequestMutipleDashboard;
};
export default function useLoadDashboardData({ state }: Props) {
  const navigate = useNavigate();

  const [isProjectDataLoading, setIsProjectDataLoading] = useState(false);
  const [projectData, setProjectData] = useState<
    IResponseGetProjectDataForDashboardRowData[]
  >([]);
  const [isProjectLecturerDataLoading, setIsProjectLecturerDataLoading] =
    useState(false);
  const [projectLecturerData, setProjectLecturerData] = useState<
    IResponseGetProjectLecturerDataForDashboardRowData[]
  >([]);
  const [isProjectVendorDataLoading, setIsProjectVendorDataLoading] =
    useState(false);
  const [projectVendorData, setProjectVendorData] = useState<
    IResponseGetProjectVendorDataForDashboardRowData[]
  >([]);
  const [isProjectCustomerDataLoading, setIsProjectCustomerDataLoading] =
    useState(false);
  const [projectCustomerData, setProjectCustomerData] =
    useState<IclassifiedNewAndOldCustomerByYear>({});
  const budget_year = Number(state?.start_budget_year) + 543;

  useEffect(() => {
    if (state === null) {
      navigate("/dashboard");
    } else {
      if (!projectData.length) {
        LoadProjectData(state);
      }
      if (!projectLecturerData.length) {
        LoadProjectLecturerData(state);
      }
      if (!projectVendorData.length) {
        LoadProjectVendorData(state);
      }
      if (!projectCustomerData.length) {
        LoadProjectCustomerData(state);
      }
    }
    // eslint-disable-next-line
  }, []);

  const LoadProjectData = async (state: Props["state"]) => {
    setIsProjectDataLoading(true);
    try {
      let page = 1;
      let maxPage = 1;

      const project_progress_status_id = state?.project_progress_status_id;
      const start_budget_year = state?.start_budget_year;
      const end_budget_year = state?.end_budget_year;

      let bulkData: IResponseGetProjectDataForDashboardRowData[] = [];

      while (page <= maxPage) {
        const reqbody: IRequestDataForDashboard = {
          page,
          project_progress_status_id,
          start_budget_year,
          end_budget_year,
        };
        const res = await api.post("/dashboard/projects", reqbody);
        const data = res.data.body as IResponseGetProjectDataForDashboard;
        maxPage = data.totalPages;
        bulkData = [...bulkData, ...data.lists];
        page += 1;

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }
      setProjectData(bulkData);
    } catch (err) {
      notifications.show({
        title: "เกิดข้อผิดพลาด",
        message: "กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล",
        color: "red",
        icon: <IconExclamationCircle size="1rem" />,
      });
      navigate("/dashboard");
      console.log(err);
    }
    setIsProjectDataLoading(false);
  };

  const LoadProjectLecturerData = async (state: Props["state"]) => {
    setIsProjectLecturerDataLoading(true);
    try {
      let page = 1;
      let maxPage = 1;

      const project_progress_status_id = state?.project_progress_status_id;
      const start_budget_year = state?.start_budget_year;
      const end_budget_year = state?.end_budget_year;

      let bulkData: IResponseGetProjectLecturerDataForDashboardRowData[] = [];

      while (page <= maxPage) {
        const reqbody: IRequestDataForDashboard = {
          page,
          project_progress_status_id,
          start_budget_year,
          end_budget_year,
        };
        const res = await api.post("/dashboard/project_lecturers", reqbody);
        const data = res.data
          .body as IResponseGetProjectLecturerDataForDashboard;
        maxPage = data.totalPages;
        bulkData = [...bulkData, ...data.lists];
        page += 1;

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }

      setProjectLecturerData(bulkData);
    } catch (err) {
      notifications.show({
        title: "เกิดข้อผิดพลาด",
        message: "กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล",
        color: "red",
        icon: <IconExclamationCircle size="1rem" />,
      });
      navigate("/dashboard");
    }
    setIsProjectLecturerDataLoading(false);
  };

  const LoadProjectVendorData = async (state: Props["state"]) => {
    setIsProjectVendorDataLoading(true);
    try {
      let page = 1;
      let maxPage = 1;

      const project_progress_status_id = state?.project_progress_status_id;
      const start_budget_year = state?.start_budget_year;
      const end_budget_year = state?.end_budget_year;

      let bulkData: IResponseGetProjectVendorDataForDashboardRowData[] = [];

      while (page <= maxPage) {
        const reqbody: IRequestDataForDashboard = {
          page,
          project_progress_status_id,
          start_budget_year,
          end_budget_year,
        };
        const res = await api.post("/dashboard/project_vendors", reqbody);
        const data = res.data.body as IResponseGetProjectVendorDataForDashboard;
        maxPage = data.totalPages;
        bulkData = [...bulkData, ...data.lists];
        page += 1;

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }

      setProjectVendorData(bulkData);
    } catch (err) {
      notifications.show({
        title: "เกิดข้อผิดพลาด",
        message: "กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล",
        color: "red",
        icon: <IconExclamationCircle size="1rem" />,
      });
      navigate("/dashboard");
    }
    setIsProjectVendorDataLoading(false);
  };

  const LoadProjectCustomerData = async (state: Props["state"]) => {
    setIsProjectCustomerDataLoading(true);
    try {
      let page = 1;
      let maxPage = 1;

      const project_progress_status_id = state?.project_progress_status_id;
      const start_budget_year = state?.start_budget_year;
      const end_budget_year = state?.end_budget_year;
      const sby = Number(start_budget_year);
      const eby = Number(state?.end_budget_year ?? start_budget_year);

      let bulkData: IResponseGetProjectCustomerDataForDashboardRowData[] = [];

      while (page <= maxPage) {
        const reqbody: IRequestDataForDashboard = {
          page,
          project_progress_status_id,
          start_budget_year,
          end_budget_year,
        };
        const res = await api.post("/dashboard/project_customers", reqbody);
        const data = res.data
          .body as IResponseGetProjectCustomerDataForDashboard;
        maxPage = data.totalPages;
        bulkData = [...bulkData, ...data.lists];
        page += 1;

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }

      const classifiedNewAndOldCustomerByYear: IclassifiedNewAndOldCustomerByYear =
        {};

      const cleanCustomerData: IcleanCustomerData[] = [];

      bulkData.forEach((agency) => {
        cleanCustomerData[agency.agency_id] = {
          ...agency,
          ...cleanCustomerData[agency.agency_id],
          [agency.project_budget_year]: true,
        };
      });

      const cleanCustomerDataClearEmpty = cleanCustomerData.filter(
        (customer) => customer,
      );

      for (let index = sby; index <= eby; index++) {
        classifiedNewAndOldCustomerByYear[index] = {
          newcustomer: [],
          oldcustomer: [],
        };
      }
      cleanCustomerDataClearEmpty.forEach((agency) => {
        for (let yearProject = sby; yearProject <= eby; yearProject++) {
          if (agency[yearProject]) {
            var latestActiveYear = 0;

            //  ตรวจว่าลูกค้ามี record โครงการในปีงบประมาณย้อนกลับไป 1 หรือ 2 หรือ 3 ปีที่แล้ว
            if (agency[yearProject - 1]) {
              latestActiveYear = yearProject - 1;
            } else if (agency[yearProject - 2]) {
              latestActiveYear = yearProject - 2;
            } else if (agency[yearProject - 3]) {
              latestActiveYear = yearProject - 3;
            }

            let agencyWithLastestActiveYear = {
              latest_active_year:
                latestActiveYear === 0
                  ? "ไม่พบข้อมูล 3 ปีย้อนหลัง"
                  : latestActiveYear,
              ...agency,
            };
            if (
              latestActiveYear ||
              // ตรวจว่าปีงบประมาณโครงการ มากกว่า ปีงบประมาณแรกที่เป็นลูกค้า
              (yearProject > agency.on_boarding_year &&
                yearProject - agency.on_boarding_year <= 3)
            ) {
              // นับเป็นลูกค้าเก่า
              classifiedNewAndOldCustomerByYear[yearProject].oldcustomer.push(
                agencyWithLastestActiveYear,
              );
            } else {
              // นับเป็นลูกค้าใหม่
              classifiedNewAndOldCustomerByYear[yearProject].newcustomer.push(
                agencyWithLastestActiveYear,
              );
            }
          }
        }
      });

      setProjectCustomerData(classifiedNewAndOldCustomerByYear);
    } catch (err) {
      console.log(err);

      notifications.show({
        title: "เกิดข้อผิดพลาด",
        message: "กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล",
        color: "red",
        icon: <IconExclamationCircle size="1rem" />,
      });
      navigate("/dashboard");
    }
    setIsProjectCustomerDataLoading(false);
  };

  const projectAgencyData = _(projectData)
    .groupBy("agency_name")
    .map((values, key) => ({
      agency_name: key,
      agency_type_name: values[0].agency_type_name,
      agency_budget_year: values[0].agency_budget_year,
      total_project_amount: _.uniqBy(values, "project_id").length,
      total_project_budget: _.sumBy(
        values.map((e) => {
          return { ...e, project_budget: Number(e.project_budget) };
        }),
        "project_budget",
      ),
    }))
    .value();

  const isDataLoading =
    isProjectDataLoading ||
    isProjectLecturerDataLoading ||
    isProjectVendorDataLoading ||
    isProjectCustomerDataLoading;

  const isDataNotFound =
    isDataLoading &&
    !projectData.length &&
    !projectLecturerData.length &&
    !projectVendorData.length;

  if (!state) {
    navigate("/dashboard");
  }

  return {
    isProjectCustomerDataLoading,
    isProjectVendorDataLoading,
    isProjectLecturerDataLoading,
    isProjectDataLoading,
    budget_year,
    projectData,
    projectLecturerData,
    projectVendorData,
    projectCustomerData,
    projectAgencyData,
    isDataLoading,
    isDataNotFound,
  };
}
