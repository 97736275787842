import AgencyAmountByAgencyTypePieChart from "@components/chart/AgencyAmountByAgencyTypePieChart";
import AgencyRankingMultipleYearsChart from "@components/chart/AgencyRankingMultipleYearsChart";
import ChairmanRankingMutipleYearsChart from "@components/chart/ChairmanRankingMutipleYearsChart";
import ColumnChart from "@components/chart/ColumnChart";
import CoordinatorRankingMultipleYearsChart from "@components/chart/CoordinatorRankingMultipleYearsChart";
import LecturerRankingMultipleYearsChart from "@components/chart/LecturerRankingMultipleYearsChart";
import ProjectBudgetByAgencyTypePieChart from "@components/chart/ProjectBudgetByAgencyTypePieChart";
import ProjectBudgetByProjectTypePieChart from "@components/chart/ProjectBudgetByProjectTypePieChart";
import ProjectBudgetByQuarterColumnChart from "@components/chart/ProjectBudgetByQuarterColumnChart";
import ProjectRankingChart from "@components/chart/ProjectRankingChart";
import ProjectStatsGroup from "@components/chart/ProjectStatsGroup";
import VendorRankingMultipleYearsChart from "@components/chart/VendorRankingMultipleYearsChart";
import VendorStatsGroup from "@components/chart/VendorStatsGroup";
import LoadingPage from "@components/loading/LoadingPage";
import PageLayout from "@components/page/PageLayout";
import TextDashboardSectionTitle from "@components/text/TextDashboardSectionTitle";
import api from "@config/api";
import {
  IRequestDataForDashboard,
  IRequestMutipleDashboard,
  IcleanCustomerData,
  IResponseGetProjectCustomerDataForDashboard,
  IResponseGetProjectCustomerDataForDashboardRowData,
  IResponseGetProjectDataForDashboard,
  IResponseGetProjectDataForDashboardRowData,
  IResponseGetProjectLecturerDataForDashboard,
  IResponseGetProjectLecturerDataForDashboardRowData,
  IResponseGetProjectVendorDataForDashboard,
  IResponseGetProjectVendorDataForDashboardRowData,
} from "@interfaces/api/IDashboard";
import { Button, Flex, Paper } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconExclamationCircle } from "@tabler/icons-react";
import exportDataDashboardToExcel from "@utils/exportDataDashboardToExcel";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IclassifiedNewAndOldCustomerByYear } from "../../interfaces/api/IDashboard";
import AgencyISOStatsGroup from "@components/chart/AgencyISOStatsGroup";
import AgenciesAmountISOColumnChart from "@components/chart/AgenciesAmountISOColumnChart";

const DashboardMutiple = () => {
  let { state = null }: { state: IRequestMutipleDashboard | null } =
    useLocation();

  const [isProjectDataLoading, setIsProjectDataLoading] = useState(false);
  const [projectData, setProjectData] = useState<
    IResponseGetProjectDataForDashboardRowData[]
  >([]);
  const [isProjectLecturerDataLoading, setIsProjectLecturerDataLoading] =
    useState(false);
  const [projectLecturerData, setProjectLecturerData] = useState<
    IResponseGetProjectLecturerDataForDashboardRowData[]
  >([]);
  const [isProjectVendorDataLoading, setIsProjectVendorDataLoading] =
    useState(false);
  const [projectVendorData, setProjectVendorData] = useState<
    IResponseGetProjectVendorDataForDashboardRowData[]
  >([]);
  const [isProjectCustomerDataLoading, setIsProjectCustomerDataLoading] =
    useState(false);
  const [projectCustomerData, setProjectCustomerData] =
    useState<IclassifiedNewAndOldCustomerByYear>({});
  const budget_year = `${Number(state?.start_budget_year) + 543} - ${Number(state?.end_budget_year) + 543}`;

  const navigate = useNavigate();
  useEffect(() => {
    if (state === null) {
      navigate("/dashboard");
    } else {
      if (!projectData.length) {
        LoadProjectData(state);
      }
      if (!projectLecturerData.length) {
        LoadProjectLecturerData(state);
      }
      if (!projectVendorData.length) {
        LoadProjectVendorData(state);
      }
      if (!projectCustomerData.length) {
        LoadProjectCustomerData(state);
      }
    }
    // eslint-disable-next-line
  }, []);

  const LoadProjectData = async (state: IRequestMutipleDashboard) => {
    setIsProjectDataLoading(true);
    try {
      let page = 1;
      let maxPage = 1;

      const project_progress_status_id = state?.project_progress_status_id;
      const start_budget_year = state?.start_budget_year;
      const end_budget_year = state?.end_budget_year;

      let bulkData: IResponseGetProjectDataForDashboardRowData[] = [];

      while (page <= maxPage) {
        const reqbody: IRequestDataForDashboard = {
          page,
          project_progress_status_id,
          start_budget_year,
          end_budget_year,
        };
        const res = await api.post("/dashboard/projects", reqbody);
        const data = res.data.body as IResponseGetProjectDataForDashboard;
        maxPage = data.totalPages;
        bulkData = [...bulkData, ...data.lists];
        page += 1;

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }

      setProjectData(bulkData);
    } catch (err) {
      notifications.show({
        title: "เกิดข้อผิดพลาด",
        message: "กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล",
        color: "red",
        icon: <IconExclamationCircle size="1rem" />,
      });
      navigate("/dashboard");
    }
    setIsProjectDataLoading(false);
  };

  const LoadProjectLecturerData = async (state: IRequestMutipleDashboard) => {
    setIsProjectLecturerDataLoading(true);
    try {
      let page = 1;
      let maxPage = 1;

      const project_progress_status_id = state?.project_progress_status_id;
      const start_budget_year = state?.start_budget_year;
      const end_budget_year = state?.end_budget_year;

      let bulkData: IResponseGetProjectLecturerDataForDashboardRowData[] = [];

      while (page <= maxPage) {
        const reqbody: IRequestDataForDashboard = {
          page,
          project_progress_status_id,
          start_budget_year,
          end_budget_year,
        };
        const res = await api.post("/dashboard/project_lecturers", reqbody);
        const data = res.data
          .body as IResponseGetProjectLecturerDataForDashboard;
        maxPage = data.totalPages;
        bulkData = [...bulkData, ...data.lists];
        page += 1;

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }

      setProjectLecturerData(bulkData);
    } catch (err) {
      notifications.show({
        title: "เกิดข้อผิดพลาด",
        message: "กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล",
        color: "red",
        icon: <IconExclamationCircle size="1rem" />,
      });
      navigate("/dashboard");
    }
    setIsProjectLecturerDataLoading(false);
  };

  const LoadProjectVendorData = async (state: IRequestMutipleDashboard) => {
    setIsProjectVendorDataLoading(true);
    try {
      let page = 1;
      let maxPage = 1;

      const project_progress_status_id = state?.project_progress_status_id;
      const start_budget_year = state?.start_budget_year;
      const end_budget_year = state?.end_budget_year;

      let bulkData: IResponseGetProjectVendorDataForDashboardRowData[] = [];

      while (page <= maxPage) {
        const reqbody: IRequestDataForDashboard = {
          page,
          project_progress_status_id,
          start_budget_year,
          end_budget_year,
        };
        const res = await api.post("/dashboard/project_vendors", reqbody);
        const data = res.data.body as IResponseGetProjectVendorDataForDashboard;
        maxPage = data.totalPages;
        bulkData = [...bulkData, ...data.lists];
        page += 1;

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }

      setProjectVendorData(bulkData);
    } catch (err) {
      notifications.show({
        title: "เกิดข้อผิดพลาด",
        message: "กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล",
        color: "red",
        icon: <IconExclamationCircle size="1rem" />,
      });
      navigate("/dashboard");
    }
    setIsProjectVendorDataLoading(false);
  };

  const LoadProjectCustomerData = async (state: IRequestMutipleDashboard) => {
    setIsProjectCustomerDataLoading(true);
    try {
      let page = 1;
      let maxPage = 1;

      const project_progress_status_id = state?.project_progress_status_id;
      const start_budget_year = state?.start_budget_year;
      const end_budget_year = state?.end_budget_year;
      const sby = Number(start_budget_year);
      const eby = Number(state?.end_budget_year ?? start_budget_year);

      let bulkData: IResponseGetProjectCustomerDataForDashboardRowData[] = [];

      while (page <= maxPage) {
        const reqbody: IRequestDataForDashboard = {
          page,
          project_progress_status_id,
          start_budget_year,
          end_budget_year,
        };
        const res = await api.post("/dashboard/project_customers", reqbody);
        const data = res.data
          .body as IResponseGetProjectCustomerDataForDashboard;
        maxPage = data.totalPages;
        bulkData = [...bulkData, ...data.lists];
        page += 1;

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }

      const classifiedNewAndOldCustomerByYear: IclassifiedNewAndOldCustomerByYear =
        {};

      const cleanCustomerData: IcleanCustomerData[] = [];

      bulkData.forEach((agency) => {
        cleanCustomerData[agency.agency_id] = {
          ...agency,
          ...cleanCustomerData[agency.agency_id],
          [agency.project_budget_year]: true,
        };
      });

      const cleanCustomerDataClearEmpty = cleanCustomerData.filter(
        (customer) => customer,
      );

      for (let index = sby; index <= eby; index++) {
        classifiedNewAndOldCustomerByYear[index] = {
          newcustomer: [],
          oldcustomer: [],
        };
      }
      cleanCustomerDataClearEmpty.forEach((agency) => {
        for (let yearProject = sby; yearProject <= eby; yearProject++) {
          if (agency[yearProject]) {
            var latestActiveYear = 0;

            //  ตรวจว่าลูกค้ามี record โครงการในปีงบประมาณย้อนกลับไป 1 หรือ 2 หรือ 3 ปีที่แล้ว
            if (agency[yearProject - 1]) {
              latestActiveYear = yearProject - 1;
            } else if (agency[yearProject - 2]) {
              latestActiveYear = yearProject - 2;
            } else if (agency[yearProject - 3]) {
              latestActiveYear = yearProject - 3;
            }

            let agencyWithLastestActiveYear = {
              latest_active_year:
                latestActiveYear === 0
                  ? "ไม่พบข้อมูล 3 ปีย้อนหลัง"
                  : latestActiveYear,
              ...agency,
            };
            if (
              latestActiveYear ||
              // ตรวจว่าปีงบประมาณโครงการ มากกว่า ปีงบประมาณแรกที่เป็นลูกค้า
              (yearProject > agency.on_boarding_year &&
                yearProject - agency.on_boarding_year <= 3)
            ) {
              // นับเป็นลูกค้าเก่า
              classifiedNewAndOldCustomerByYear[yearProject].oldcustomer.push(
                agencyWithLastestActiveYear,
              );
            } else {
              // นับเป็นลูกค้าใหม่
              classifiedNewAndOldCustomerByYear[yearProject].newcustomer.push(
                agencyWithLastestActiveYear,
              );
            }
          }
        }
      });

      setProjectCustomerData(classifiedNewAndOldCustomerByYear);
    } catch (err) {
      console.log(err);

      notifications.show({
        title: "เกิดข้อผิดพลาด",
        message: "กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล",
        color: "red",
        icon: <IconExclamationCircle size="1rem" />,
      });
      navigate("/dashboard");
    }
    setIsProjectCustomerDataLoading(false);
  };

  const pageTitle = `สรุปผลข้อมูลการดำเนินโครงการประจำปีงบประมาณ ${budget_year}`;

  const ProjectAgencyData = _(projectData)
    .groupBy("agency_name")
    .map((values, key) => ({
      agency_name: key,
      agency_type_name: values[0].agency_type_name,
      agency_budget_year: values[0].agency_budget_year,
      total_project_amount: _.uniqBy(values, "project_id").length,
      total_project_budget: _.sumBy(
        values.map((e) => {
          return { ...e, project_budget: Number(e.project_budget) };
        }),
        "project_budget",
      ),
    }))
    .value();

  const handleExportClick = () => {
    exportDataDashboardToExcel(
      {
        projectData: projectData,
        projectLecturerData: projectLecturerData,
        projectVendorData: projectVendorData,
      },
      "reportdata.xlsx",
    );
  };

  if (
    isProjectDataLoading ||
    isProjectLecturerDataLoading ||
    isProjectVendorDataLoading ||
    isProjectCustomerDataLoading ||
    state === null
  ) {
    return <LoadingPage />;
  }

  if (
    !projectData.length &&
    !projectLecturerData.length &&
    !projectVendorData.length
  ) {
    return <PageLayout pageTitle={pageTitle}>ไม่พบข้อมูล</PageLayout>;
  }

  return (
    <PageLayout pageTitle={pageTitle}>
      <Paper mb="lg" className="noprint">
        <Button mr="lg" onClick={() => window.print()}>
          ดาวน์โหลด PDF
        </Button>
        <Button mr="lg" onClick={handleExportClick}>
          ดาวน์โหลด Excel
        </Button>
      </Paper>
      <Flex direction="column">
        {projectData.length > 0 && (
          <>
            <div>
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลโครงการ
              </TextDashboardSectionTitle>
              {state?.opt1_project_stats && (
                <ProjectStatsGroup data={projectData} />
              )}
              {state?.opt2_project_quarter && (
                <div className="pagebreak">
                  <ColumnChart
                    title={`ยอดมูลค่าโครงการทั้งหมดแบ่งสัดส่วนตามปีงบประมาณ ประจำปีงบประมาณ ${budget_year}`}
                    data={projectData}
                    keySort={"quarter"}
                    keyValue={"project_budget"}
                    keyType={"project_budget_year"}
                    typeName="ปีงบประมาณ"
                    valueName="มูลค่างบประมาณโครงการ"
                  />
                </div>
              )}
            </div>
            {state?.opt2_project_quarter && (
              <div className="pagebreak">
                <ProjectBudgetByQuarterColumnChart
                  title={`ยอดมูลค่าโครงการทั้งหมดแบ่งสัดส่วนตามไตรมาส ประจำปีงบประมาณ ${budget_year}`}
                  data={projectData}
                />
              </div>
            )}
            {state?.opt3_6_budget_by_project_type && (
              <div className="pagebreak">
                <ProjectBudgetByProjectTypePieChart
                  title={`ยอดมูลค่าโครงการทั้งหมด แบ่งสัดส่วนตามประเภทกิจกรรมบริการวิชาการ ประจำปีงบประมาณ ${budget_year}`}
                  typeName="ประเภทกิจกรรมบริการวิชาการ"
                  valueName="งบประมาณ"
                  data={projectData}
                />
              </div>
            )}
            {state?.opt3_project_budget_ranking && (
              <div className="pagebreak">
                <ProjectRankingChart
                  title={`จัดอันดับโครงการสำคัญที่มียอดมูลค่าโครงการสูงสุด 5 อันดับแรก ประจำปีงบประมาณ ${budget_year}`}
                  data={projectData}
                />
              </div>
            )}

            <div className="pagebreak">
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลหน่วยงาน/ลูกค้า
              </TextDashboardSectionTitle>
              {state?.opt4_agency_stats && (
                <AgencyISOStatsGroup data={projectCustomerData} />
              )}
              {state?.opt5_agency_new_and_old && (
                <AgenciesAmountISOColumnChart
                  title={`ยอดจำนวนลูกค้ารายใหม่และรายเก่า ประจำปีงบประมาณ ${budget_year}`}
                  data={projectCustomerData}
                  keySort={"quarter"}
                  keyValue={"project_budget"}
                  keyType={"quarter"}
                  typeName="ปีงบประมาณ"
                  valueName="จำนวนหน่วยงาน"
                />
              )}
            </div>
            {state?.opt6_agency_by_type && (
              <div className="pagebreak">
                <AgencyAmountByAgencyTypePieChart
                  title={`ยอดจำนวนลูกค้าทั้งหมด แบ่งสัดส่วนตามประเภทหน่วยงาน ประจำปีงบประมาณ ${budget_year}`}
                  typeName="ประเภทหน่วยงาน"
                  valueName="หน่วยงาน"
                  data={ProjectAgencyData}
                />
              </div>
            )}
            {state?.opt7_budget_by_agency_type && (
              <div className="pagebreak">
                <ProjectBudgetByAgencyTypePieChart
                  title={`ยอดมูลค่าโครงการทั้งหมด แบ่งสัดส่วนตามประเภทหน่วยงาน ประจำปีงบประมาณ ${budget_year}`}
                  typeName="ประเภทหน่วยงาน"
                  valueName="งบประมาณ"
                  data={projectData}
                />
              </div>
            )}
            {state?.opt8_agency_budget_ranking && (
              <div className="pagebreak">
                <AgencyRankingMultipleYearsChart
                  title={`จัดอันดับลูกค้าสำคัญที่มียอดมูลค่าโครงการสูงสุด ประจำปีงบประมาณ ${budget_year}`}
                  data={projectData}
                />
              </div>
            )}
            <div className="pagebreak">
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลผู้ประสานงานโครงการ
              </TextDashboardSectionTitle>
              {state?.opt9_coodinator_budget_rank && (
                <CoordinatorRankingMultipleYearsChart
                  title={`จัดอันดับผู้ประสานงานโครงการที่มียอดมูลค่าโครงการสูงสุด ประจำปีงบประมาณ ${budget_year}`}
                  data={projectData}
                />
              )}
            </div>

            <div className="pagebreak">
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลประธานโครงการ
              </TextDashboardSectionTitle>
              {state?.opt10_chairman_budget_rank && (
                <ChairmanRankingMutipleYearsChart
                  title={`จัดอันดับประธานโครงการที่มียอดมูลค่าโครงการสูงสุด ประจำปีงบประมาณ ${budget_year}`}
                  data={projectData}
                />
              )}
            </div>
          </>
        )}
        {projectLecturerData.length > 0 && (
          <div className="pagebreak">
            <TextDashboardSectionTitle>
              สรุปผลข้อมูลวิทยากร
            </TextDashboardSectionTitle>
            {state?.opt11_lecturer_rating_rank && (
              <LecturerRankingMultipleYearsChart
                title={`จัดอันดับวิทยากรที่ได้รับค่าเฉลี่ยผลการประเมินคะแนนความพึงพอใจสูงสุด ประจำปีงบประมาณ ${budget_year}`}
                data={projectLecturerData}
              />
            )}
          </div>
        )}
        {projectVendorData.length > 0 && (
          <>
            <div className="pagebreak">
              <TextDashboardSectionTitle>
                สรุปผลข้อมูลผู้ค้า
              </TextDashboardSectionTitle>
              {state?.opt12_vendor_stats && (
                <VendorStatsGroup data={projectVendorData} />
              )}
            </div>
            {state?.opt13_vendor_rating_rank && (
              <div className="pagebreak">
                <VendorRankingMultipleYearsChart
                  title={`จัดอันดับผู้ค้าที่ได้รับค่าเฉลี่ยผลการประเมินคะแนนความพึงพอใจสูงสุด ${budget_year}`}
                  data={projectVendorData}
                />
              </div>
            )}
          </>
        )}
      </Flex>
    </PageLayout>
  );
};

export default DashboardMutiple;
